import React, { FC, useEffect, useState, useRef } from 'react';
import parsePath from 'src/lib/parse-path.js';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';

import { MARKETO_BASE_URL } from 'src/env';
import useMarketo from 'src/hooks/useMarketo';
import { useTrack } from 'src/contexts/AnalyticsContext';
import { useAdBlockDetector, useSegmentId } from 'src/hooks';
import {
  getCookie,
  utmParametersToKeysMapping,
} from 'public/assets/js/analytics/cookie';
import { formData } from 'src/store/form';

// helpers
import {
  prependLocale,
  prependStagingHash,
  mapSearchParamsToObject,
  appendSuccessParam,
} from 'src/lib/utils';
import { Locales, FormTypes } from 'src/lib/constants';

export interface RenderProps {
  control: ReturnType<typeof useForm>['control'];
  errors: Record<string, unknown>;
  /* @TODO: update react-hook-form, types - https://jira.plaid.com/browse/SITE-2779
  /* register accepts varying arguments & the ReturnType method fails */
  /* eslint-disable @typescript-eslint/no-explicit-any */
  register: any;
  setValue?: ReturnType<typeof useForm>['setValue'];
}
interface Props {
  redirectUrl?: string;
  marketoKey: number;
  onSubmit?: () => void;
  shouldForward?: boolean;
  render: (RenderProps) => React.ReactNode;
  trackingId?: string;
  locale?: string;
  formType?: FormTypes;
}

declare global {
  interface Window {
    Plaid: {
      getStoredUtmParams: () => Record<string, unknown>;
    };
    MktoForms2;
    qualified;
  }
}

// marketoForm Function
const MarketoForm: FC<Props> = ({
  redirectUrl,
  marketoKey,
  onSubmit = () => {
    return undefined;
  },
  shouldForward = true,
  render,
  trackingId = 'MARKETO_FORM',
  locale = '',
  formType = FormTypes.Legacy,
}) => {
  const {
    register,
    handleSubmit,
    errors, // @TODO: deprecated API - https://jira.plaid.com/browse/SITE-2779
    control,
    setValue,
    formState,
    setError,
    clearErrors,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const [formSubmitCount, setFormSubmitCount] = useState(0);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const marketoFormLoaded = useRef(null);
  const track = useTrack();
  const router = useRouter();
  const basePath = router?.basePath || '';
  const currentPage = parsePath(router?.asPath).pathname;
  useMarketo({
    baseUrl: MARKETO_BASE_URL,
    munchkinId: '495-WRE-561',
    formId: marketoKey,
    scriptLoaded,
    setScriptLoaded,
    marketoFormLoaded,
  });

  const hasAdBlocker = useAdBlockDetector();
  const segmentId = useSegmentId();

  const submitMarketo = (data) => {
    setIsSubmitting(true);

    const fromDemo = localStorage.getItem('from-demo') ?? '';

    marketoFormLoaded.current.then((marketoForm) => {
      const valObj = Object.assign({}, data, getSourceTrackingObj(), {
        hasAdBlocker,
        segmentajsanonymousid: segmentId,
        coastDemo: fromDemo,
      });
      // Marketo documentation for methods used: https://developers.marketo.com/javascript-api/forms/api-reference/
      marketoForm.vals(valObj);

      // 2142 are forms that contain solutions checkboxes
      if (marketoKey === 2142) {
        if (
          !formData.solutions.options.some((c) => {
            return !!valObj[c.id];
          })
        ) {
          setError('solutions', {
            type: 'custom',
            message:
              'Please select at least one solution you are interested in.',
          });
          setIsSubmitting(false);
          return;
        } else {
          clearErrors('solutions');
        }
      }

      // Once we reach here our React form validation passed,
      // but it still needs to be checked if native Marketo form validation passed
      const nativeValidation = marketoForm.validate();
      if (!nativeValidation) {
        setIsSubmitting(false);
        track({ type: `${trackingId}_NATIVE_VALIDATION_FAILED` });
        return;
      }

      marketoForm.submit();
      marketoForm.onSuccess((values, followUpUrl) => {
        if (window?.qualified && qualifiedMeetingForms.has(marketoKey)) {
          window?.qualified('saveFormData', {
            company: valObj?.Company,
            conversion_url: window?.location?.href,
            country: valObj.Country,
            email: valObj?.Email,
            first_name: valObj?.FirstName,
            landing_page_url: valObj?.referrerUrl,
            last_name: valObj?.LastName,
            phone: valObj?.Phone,
            state: valObj?.State,
            coast_demo: valObj?.coastDemo,
            utm_campaign: valObj?.['utm_campaign__c'],
            utm_content: valObj?.['utm_content__c'],
            utm_medium: valObj?.['utm_medium__c'],
            utm_source: valObj?.['utm_source__c'],
            utm_term: valObj?.['utm_term__c'],
            ///////////////////////////////////////
            account_owner: undefined,
            city: undefined,
            consumer: undefined,
            employee_count: undefined,
            employees_routing: undefined,
            experience_control_field: undefined,
            hq_country: undefined,
            lead_source: undefined,
            lead_source_detail: undefined,
            marketing_comms_optin: undefined,
            most_recent_cta: undefined,
            name: undefined,
            nba_notes: undefined,
            original_entry_url: undefined,
            owner_id: undefined,
            playback_url: undefined,
            revenue: undefined,
            status: undefined,
            stream_control_field: undefined,
            title: undefined,
          });
        }

        if (locale && locale !== Locales.EN_US) {
          const redirectUrl = followUpUrl.replace('https://plaid.com', '');
          followUpUrl = prependStagingHash(
            prependLocale({
              locale,
              href: redirectUrl,
            }),
            basePath,
          );
        }

        // Analytics tracking
        track(
          {
            type: `${trackingId}_SUCCESS`,
            payload: {
              shouldForward,
              followUpUrl,
            },
          },
          // Pass redirect conditional as callback to track
          // to give browser time to make outbound requests first.
          // See our `track` impl in AnalyticsContext for details.
          () => {
            setIsSubmitting(false);
            // we try submission logic from parent components
            // if tracking is disabled or blocked there is a try/catch
            // that will handle this callback no matter what happens
            onSubmit();
            // we do nothing if the form is supposed to stay on the page
            // this applies to all forms
            if (!shouldForward) {
              // we show the qualified meetings experience when a form is successful
              if (window?.qualified && qualifiedMeetingForms.has(marketoKey)) {
                window.qualified(
                  'showFormExperience',
                  'experience-1669750127746',
                );
              }
              return;
            }
            // we have to treat each type of form differently in order to
            // best support backwards compatibility
            switch (formType) {
              // legacy forms that should forward will always follow
              // the followUpUrl configured in Marketo
              case FormTypes.Legacy: {
                window.location.href = appendSuccessParam(
                  followUpUrl,
                  marketoKey,
                );
                break;
              }
              // we first try to redirect to the marketo followUpUrl
              // otherwise we redirect to whatever is set in the cms button
              case FormTypes.CMS: {
                const { pathname } = parsePath(followUpUrl);
                if (followUpUrl && pathname !== currentPage) {
                  window.location.href = appendSuccessParam(
                    followUpUrl,
                    marketoKey,
                  );
                } else {
                  window.location.href = appendSuccessParam(
                    redirectUrl,
                    marketoKey,
                  );
                }
                break;
              }
              // we follow the redirect url for gated content forms
              // this wil be one of an asset or a button url as a fallback
              // it's unlikely the button url will be invoked
              case FormTypes.Gated: {
                window.location.href = appendSuccessParam(
                  redirectUrl,
                  marketoKey,
                );
                break;
              }
            }
          },
        );

        // ..bcuz of async
        marketoForm.onSuccess(async () => {
          // Data Layer
          if (window) {
            const uuid = window.generateUUID();
            const hashedEmail = await window.hashData(valObj?.Email);

            window.dataLayer.push({
              event: 'LI-CAPI-Form-Submit',
              user_data: {
                linkedinFirstPartyId: window.li_fat_id,
                sha256_email_address: hashedEmail,
                address: {
                  first_name: valObj?.FirstName,
                  last_name: valObj?.LastName,
                  country: valObj?.Country,
                },
                // jobTitle: '',
                companyName: valObj?.Company,
              },
              currency: 'USD',
              value: '0',
              event_id: uuid,
            });
          }
        });

        return false;
      });
    });
  };

  useEffect(() => {
    if (formSubmitCount === formState.submitCount) {
      if (formState.isSubmitting) {
        track({ type: `${trackingId}_SUBMIT` });
        setFormSubmitCount(formSubmitCount + 1);
      }
    }
  }, [trackingId, formState, formSubmitCount, track]);

  return (
    <>
      <form
        data-testid='marketo-form'
        onSubmit={handleSubmit(submitMarketo)}
        noValidate
      >
        {render({
          control,
          errors,
          register,
          setValue,
          isSubmitting,
          clearErrors,
        })}
      </form>
    </>
  );
};

export default MarketoForm;

// 2006 and 2007 are contact us forms
// NOTE: if this is ever updated the set needs to be maintained in
// src/components/MetaTags/MetaTags.jsx
// const qualifiedMeetingForms = new Set([2006, 2007]);
// 2683 is a test form
const qualifiedMeetingForms = new Set([2683]);

const getSourceTrackingObj = () => {
  const utmParams = global.window?.Plaid?.getStoredUtmParams();
  const urlParams = new URLSearchParams(window.location.search);
  const params = mapSearchParamsToObject(urlParams);
  const utmFallback = Object.keys(utmParametersToKeysMapping).reduce(
    (prev, curr) => {
      if (params[utmParametersToKeysMapping[curr]]) {
        prev[curr] = params[utmParametersToKeysMapping[curr]];
      }
      return prev;
    },
    {},
  );

  // @TODO: Reusing deprecated cookie.js method for now,
  // pending refactor of cookie logic.
  // https://jira.plaid.com/browse/SITE-3142
  const referrerUrl = getCookie('referrer_url');
  const referralURL = getCookie('referrer_url');
  const entryURL = getCookie('entry_url');
  const gclid = getCookie('gclid');

  return {
    ...utmParams,
    ...utmFallback,
    gclid__c: gclid,
    referrerUrl,
    referralURL,
    entryURL,
    currentURL: window.location.href,
  };
};
